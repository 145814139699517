@import '~theme/variables';

.list-item {
  border-radius: $border-radius-03;
  background: $color-neutral-06;
  margin: $space-xs 0;
  padding: $space-s;
  gap: $space-s;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  cursor: pointer;
  transition: background ease-in-out 0.2s;

  &.hasDarkBackground {
    background-color: $color-neutral-05;
  }

  &:not(.isPanel):hover {
    background: $color-quaternary;
  }

  &.isPanel {
    cursor: auto;
  }
}

.image-container {
  flex-shrink: 0;
  width: 6.4rem;
  height: 6.4rem;

  & .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: $border-radius-02;
  }
}

.details {
  overflow: hidden;

  & .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space-xs;
    white-space: nowrap;

    @include breakpoint(sm) {
      gap: $space-s;
    }
  }

  & .secondary-details {
    gap: 0.6rem;
  }
}

.icon {
  margin-left: auto;
  fill: $color-primary;
  font-size: $h3-font-size;

  @include breakpoint(sm) {
    margin-right: 0.8rem;
  }
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
}
