@import '~theme/variables';

.container {
  border: 1px solid $color-neutral-04;
  border-radius: $border-radius-03;
  padding: 2.4rem;
  background-color: $color-neutral-06;
  display: none;

  @include breakpoint(lg) {
    display: block;
  }
}

.title {
  margin-bottom: 1.6rem;
}

.item {
  border-radius: $border-radius-02;
  transition: background 160ms;
  margin-bottom: 0.4rem;

  &:hover,
  &[data-state='open'] {
    background: $color-neutral-05;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.trigger {
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: $body-font-size;
  gap: 1.2rem;
  width: 100%;
}

.indicator {
  width: 1.6rem;
  height: 1.6rem;
}

.content {
  padding: 0 0.8rem 0.8rem 4rem;
  color: $color-neutral-02;
  font-size: $body-font-size;
  margin-bottom: 0;
}
