@import '~theme/variables';

.tooltip-trigger {
  fill: $color-neutral-03;
  cursor: pointer;
  font-size: unset;
  display: inline-flex;
  gap: $space-xxs;

  &:hover,
  &:focus {
    svg {
      fill: $color-neutral-01;
    }
  }
}

.tooltip-content {
  z-index: $z-index-tooltip;
  max-width: var(--radix-popper-available-width);
  // max-height: var(--radix-tooltip-content-available-height);

  @include breakpoint(sm) {
    max-width: 36rem;
    min-width: 30rem;
  }
}

.tooltip-container {
  border: 0.1rem solid $color-neutral-01;
  border-radius: $border-radius-03;
  background: $color-neutral-06;
  padding: 2.4rem;
  box-shadow: $box-shadow-01;
  margin: 0 $space-s;
}

.icon {
  fill: $color-neutral-03;
  width: 2rem;
  height: 2rem;
}
