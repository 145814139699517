@import "~theme/variables";

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.description {
  margin: $space-m 0 0 0;

  &.marginBottomMd {
    margin-bottom: $space-m;
  }
}

.descriptionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 66.5rem;
}

.dateDescription {
  margin-top: $space-s;
}

.buttonContainer {
  margin-top: $space-s;
}

.dayLessonsCol {
  margin-top: $space-l;
}

.lessonsTitle {
  margin-bottom: $space-xs;
}